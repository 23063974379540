
var helpersDZSVG = require('./_dzsvg_helpers');
exports.checkForAdAlongTheWay = function(selfClass, argPerc){

  if (selfClass.ad_array.length) {
    for (var i2 = 0; i2 < selfClass.ad_array.length; i2++) {
      if (selfClass.ad_array[i2].time < argPerc) {
        return Number(selfClass.ad_array[i2].time);
      }
    }
  }

  return null;
}

exports.adEnd = function(selfClass){

  if (selfClass.$adContainer.children().get(0) && selfClass.$adContainer.children().get(0).api_destroy_listeners) {
    selfClass.$adContainer.children().get(0).api_destroy_listeners();
  }

  selfClass.player_user_had_first_interaction();
  selfClass.playMovie();
  selfClass.cthis.addClass('ad-transitioning-out');


  if (selfClass.initOptions.gallery_object) {
    if (selfClass.initOptions.gallery_object.get(0) && selfClass.initOptions.gallery_object.get(0).api_ad_unblock_navigation) {
      selfClass.initOptions.gallery_object.get(0).api_ad_unblock_navigation();
    }
  }

  setTimeout(function () {

    selfClass.cthis.removeClass('ad-playing');
    selfClass.cthis.removeClass('ad-transitioning-out');
    selfClass.$adContainer.children().remove();
    selfClass.isAdPlaying = false;
  }, 300)
}

exports.adSetup = function(selfClass, arg,pargs){

  var margs = {
    'called_from': 'default'

  }

  if (pargs) {
    margs = Object.assign(margs, pargs);
  }

  var o = selfClass.initOptions;

  var source = selfClass.ad_array[arg].source;
  var type = selfClass.ad_array[arg].type;
  var skip_delay = selfClass.ad_array[arg].skip_delay;
  var ad_link = selfClass.ad_array[arg].ad_link;
  var ad_time = selfClass.ad_array[arg].time;

  // console.info('%c ad_setup', ConstantsDzsvg.DEBUG_STYLING, arg, margs);

  selfClass.ad_array.splice(arg, 1);

  selfClass.cthis.appendOnce('<div class="ad-container"></div>');

  selfClass.$adContainer = selfClass.cthis.find('.ad-container').eq(0);


  // console.info('adding ad');
  //advertisment
  var aux = '<div class="vplayer-tobe"';

  //data-sourcevp="video/test.m4v"
  if (type != 'inline') {
    aux += ' data-sourcevp="' + source + '"';
  }
  if (type) {
    aux += ' data-type="' + type + '"';
  }
  if (ad_link) {
    aux += ' data-adlink="' + ad_link + '"';
  }
  if (skip_delay) {
    aux += ' data-adskip_delay="' + skip_delay + '"';
  }


  aux += '>';


  if (type == 'inline') {
    // aux+='<div class="adSource">' + source + '</div>';
    aux += source;
  }


  aux += '</div>';
  selfClass.$adContainer.show();
  selfClass.$adContainer.append(aux);
//                    console.info(aux);


  var argsForVideoPlayerAd = {};


  argsForVideoPlayerAd.design_skin = o.design_skin;
  argsForVideoPlayerAd.cueVideo = 'on';
  argsForVideoPlayerAd.is_ad = 'on';
  argsForVideoPlayerAd.parent_player = selfClass.cthis;
  argsForVideoPlayerAd.user_action = o.user_action;
  selfClass.isAdPlaying = true;

  argsForVideoPlayerAd.autoplay = 'on';



  if (ad_time < 0.1 && helpersDZSVG.is_mobile()) {
    // this is invisible

    selfClass.$adContainer.children('.vplayer-tobe').addClass('mobile-pretime-ad');
    selfClass.cthis.addClass('pretime-ad-setuped');
    if (o.gallery_object) {
      o.gallery_object.addClass('pretime-ad-setuped');
    }
  }


  // console.info('[dzsvg] [vp] setup ad - ',argsForVideoPlayerAd);

  selfClass.$adContainer.children('.vplayer-tobe').addClass('is-ad');
  selfClass.$adContainer.children('.vplayer-tobe').vPlayer(argsForVideoPlayerAd);


  if (o.gallery_object) {
    if (o.gallery_object.get(0) && o.gallery_object.get(0).api_ad_block_navigation) {
      o.gallery_object.get(0).api_ad_block_navigation();
    }
  }


  setTimeout(function () {

    selfClass.cthis.addClass('ad-playing');
  }, 100);
  selfClass.isAdPlaying = true;
  selfClass.pauseMovie({
    'called_from': 'ad_setup'
  });
}

exports.check_if_ad_must_be_played = function(selfClass) {
  if (selfClass.cthis.attr('data-adsource') && selfClass.cthis.data('adplayed') != 'on') {


    if (helpersDZSVG.is_ios()) {
      setTimeout(function () {
        // console.info("PAUSE VIDEO FOR AD")
        selfClass.pauseMovie({
          'called_from': 'check_if_ad_must_be_played'
        });

        if (selfClass.dataType == 'youtube') {
          selfClass._videoElement.stopVideo();
        }

        selfClass.seek_to_perc(0);
        // if(video && selfClass._videoElement.webkitExitFullScreen){
        //
        //     selfClass._videoElement.webkitExitFullScreen();
        //     document.webkitExitFullscreen();
        // }
      }, 1000);
    }

    var o = selfClass.initOptions;

    if (o.gallery_object && o.gallery_object.get(0) && o.gallery_object.get(0).api_setup_ad) {

      o.gallery_object.get(0).api_setup_ad(cthis);

      selfClass.cthis.data('adplayed', 'on');

      return false;
    }

  }

}
