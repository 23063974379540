exports.default_opts = {
  totalWidth: ""
  , totalHeight: ""
  , init_on: "init"
  , totalHeightDifferenceOnParent: ""
  , forceVideoWidth: ''
  , forceVideoHeight: ''
  , randomise: "off",

  // -- autoplay
  autoplay: "off", // -- autoplay ( deprecated )
  autoplayFirstVideo: undefined, // -- autoplay ( deprecated )
  autoplayNext: "on",  // -- play the next video when one finishes
  autoplay_ad: "on", // -- deprecated
  cueFirstVideo: 'on', // -- load first video

  loop_playlist: "on" // -- loop the playlist from the beggining when the end has been reached
  , playorder: "normal" // -- normal or reverse
  , menu_position: 'right'
  , menuitem_width: "default"// -- deprecated
  , menuitem_height: "default"// -- deprecated
  , menuitem_space: "0"// -- deprecated
  , easing_speed: ""
  , nav_type: "thumbs" // -- "thumbs" or "thumbsandarrows" or "scroller"
  , nav_space: '0' // -- deprecated
  , transition_type: "slideup"
  , design_skin: ''
  , videoplayersettings: {} // array or string
  , embedCode: ''
  , php_media_data_retriever: '' // -- this can help get the video meta data for youtube and vimeo
  , design_navigationUseEasing: 'off'
  , logo: ''
  , logoLink: ''
  , settings_enable_linking: 'off' // -- enable deeplinking on video gallery items
  , settings_mode: 'normal' /// -- normal / wall / rotator / rotator3d / slider
  , mode_normal_video_mode: 'auto' // -- auto or "one" ( only one video player )
  , settings_disableVideo: 'off' //disable the video area
  , settings_enableHistory: 'off' // html5 history api for link type items
  , settings_enableHistory_fornormalitems: 'off' // html5 history api for normal items
  , settings_ajax_extraDivs: '' // extra divs to pull in the ajax request
  , startItem: 'default'
  , settings_separation_mode: 'normal' // === normal ( no pagination ) or pages or scroll or button
  , settings_separation_pages: []
  , settings_separation_pages_number: '5' //=== the number of items per 'page'
  , nav_type_outer_grid: 'dzs-layout--4-cols' // -- four-per-row
  , nav_type_outer_max_height: '' // -- enable a scroller if menu height bigger then max_height
  , settings_menu_overlay: 'off' // -- an overlay to appear over the menu
  , search_field: 'off' // -- an overlay to appear over the menu
  , search_field_con: null // -- an overlay to appear over the menu
  , disable_videoTitle: "off" // -- do not auto set the video title
  , nav_type_auto_scroll: "off" // -- auto scroll nav
  , settings_trigger_resize: '0' // -- a force trigger resize every x ms
  , settings_go_to_next_after_inactivity: '0' // -- go to next track if no action
  , modewall_bigwidth: '800' // -- the mode wall video ( when opened ) dimensions
  , modewall_bigheight: '500'
  , default_videoHeight: '300'
  , init_all_players_at_init: 'off'
  , settings_secondCon: null
  , settings_outerNav: null
  , extra_class_slider_con: ''
  , menu_description_format: '' // -- (deprecated) use the new layout builder-- use something like "{{number}}{{menuimage}}{{title}}{{desc}}"
  , masonry_options: {}
}
