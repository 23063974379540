const {sanitizeToCssPx} = require("../../js_common/_dzs_helpers");
var defaultOptions = require('./configs/_navigationSettings').defaultSettings;

class DzsNavigation {



  constructor(parentClass, argOptions, $) {

    // console.log('defaultOptions - ', defaultOptions);
    this.$ = $;
    this.parentClass = parentClass;
    this.initOptions = Object.assign(defaultOptions, argOptions);
    this.navAttributes = Object.assign(this.initOptions, {});
    this.$mainArea = null;
    this.$mainNavigation = null;
    this.$mainNavigationClipped = null;
    this.$containerComponent = null;
    this.ultraResponsive = false;
    this.menuPosition = this.initOptions.menuPosition;

    this.initClass();

  }

  initClass() {
    const selfClass = this;
    const parentClass = this.parentClass;
    let menu_position = selfClass.initOptions.menuPosition;
    const navAttributes = this.navAttributes;

    if(this.parentClass){
      if(this.parentClass.cgallery){
        this.containerComponent = this.parentClass.cgallery;
      }else{
        if(this.parentClass.cthis){
          this.containerComponent = this.parentClass.cthis;
        }
      }
    }


    if(navAttributes.menuPosition==='left' || navAttributes.menuPosition==='right'){

      if(isNaN(Number(selfClass.initOptions.menuItemWidth)) || selfClass.initOptions.menuItemWidth==='default'){
        navAttributes.menuItemWidth = 254;
      }
    }

    // console.log(selfClass.initOptions);


    setupStructure();

    function setupStructure() {


      var aux_main_navigation_str = '<div class="main-navigation dzs-navigation--type-' + selfClass.initOptions.navigationType + '"><div class="navMain videogallery--navigation--clipped-container navigation--clipped-container"><div class="videogallery--navigation-container navigation--total-container">';
      aux_main_navigation_str += '</div></div></div>';

      parentClass.$navigationAndMainArea.addClass(`navPosition-${menu_position} navType-${navAttributes.navigationType}`);

      parentClass.$navigationAndMainArea.append('<div class="sliderMain media--main-area"><div class="sliderCon"></div></div>');
      parentClass.$navigationAndMainArea.append(aux_main_navigation_str);



      selfClass.$mainArea = parentClass.$navigationAndMainArea.find('.media--main-area');
      selfClass.$mainNavigation = parentClass.$navigationAndMainArea.find('.main-navigation');
      selfClass.$mainNavigationClipped = selfClass.$mainNavigation.find('.navigation--clipped-container');



      if(navAttributes.menuItemWidth==='default'){
        navAttributes.menuItemWidth = '';
      }
      if(navAttributes.menuItemHeight==='default'){
        navAttributes.menuItemHeight = '';
      }


      if(menu_position==='top' || menu_position==='bottom'){

      }


      if(navAttributes.menuPosition==='top'){
        selfClass.$mainArea.before(selfClass.$mainNavigation);
      }



      //console.info('o.nav_type - ',o.nav_type);
      if (navAttributes.navigationType === 'outer') {
        selfClass.$mainNavigationClipped.children().addClass('dzs-layout-item');
      }
    }
  }

  calculateDims(){
    const selfClass = this;
    let menu_position = selfClass.initOptions.menuPosition;
    const parentClass = this.parentClass;
    const navAttributes = this.navAttributes;


    let totalAreaHeightPixels = 0;
    let totalAreaWidth = parentClass.$navigationAndMainArea.outerWidth();
    let mainAreaWidth = selfClass.$mainArea.width();
    let mainNavigationWidth = selfClass.$mainNavigation.width();
    let mainNavigationDesiredWidth = navAttributes.menuItemWidth;

    parentClass.$navigationAndMainArea.children().each(function(){
      var $t = jQuery(this);

      // console.log('$t - ', $t);
      totalAreaHeightPixels+=$t.get(0).scrollHeight;
    })



    // console.log('totalAreaHeightPixels - ', totalAreaHeightPixels);


    // -- ultra-responsive
    if (this.parentClass.initOptions.settings_disableVideo !== 'on' && (navAttributes.menuPosition === 'right' || navAttributes.menuPosition === 'left')) {

      // console.log({mainAreaWidth,mainNavigationDesiredWidth});
      if(totalAreaWidth - mainNavigationDesiredWidth<mainNavigationDesiredWidth){

        if(selfClass.containerComponent){
          selfClass.containerComponent.addClass('ultra-responsive');
        }
        parentClass.$navigationAndMainArea.addClass('nav-is-ultra-responsive');
        selfClass.ultraResponsive = true;
      }else{

        parentClass.$navigationAndMainArea.removeClass('nav-is-ultra-responsive');
        selfClass.ultraResponsive = false;
      }
//                    console.info(menuitem_width, menuitem_space, totalWidth/2);
//       if (menuitem_width + menuitem_space > totalWidth / 2) {
//         //menu_position='top'
//         //console.warn('videoHeight - ',videoHeight, selfClass.$sliderMain);
//       } else {
//         //cgallery.removeClass('force-ultra-responsive-top');
//         if(this.containerComponent) {
//           this.containerComponent.removeClass('ultra-responsive');
//         }
//       }
    }


    if(navAttributes.menuPosition==='top' || navAttributes.menuPosition==='bottom' ){
      //|| parentClass.$navigationAndMainArea.hasClass('nav-is-ultra-responsive')

      // todo: move
      parentClass.$navigationAndMainArea.css({
        height: totalAreaHeightPixels+'px'
      })
    }
    if(navAttributes.menuPosition==='right' || navAttributes.menuPosition==='left'){

      if(!selfClass.ultraResponsive){

        if(navAttributes.menuItemWidth){

          selfClass.$mainNavigation.css({
            'flex': `0 0 ${sanitizeToCssPx(navAttributes.menuItemWidth)}`
          })
        }

        if(selfClass.initOptions.isSyncMainAreaAndNavigatinoAreas){
          selfClass.$mainNavigation.height(selfClass.$mainArea.height());
        }
      }else{

        selfClass.$mainNavigation.css({
          'flex': `0 0 auto`
        })
      }

    }
  }
}

exports.DzsNavigation = DzsNavigation;